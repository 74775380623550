import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * ブラウザがjavascriptを読み込んで真っ先に呼ばれる処理です
 * root　idのついたコンポーネントを取得し、その配下にコンポーネントをreact.jsで作っていきます
 */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
/**
 * ルーティングやシェアされるオブジェクトが定義されているAppコンポーネントを呼び出しています
*/
root.render(

  <App />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
